import 'magnific-popup/dist/magnific-popup.css';
import magnificPopup from 'magnific-popup';
import feather from "feather-icons";
import _ from "lodash";
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onRemoveBackorderForm_04e4e017(event) {
    toggleLoading('#saveButtonRBO', '#loadingButtonRBO');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let serializedData = $('#remove-backorder-form, input[name="selected-products[]"]:checked').serialize();
    let url = helper.data('backorder-url');

    $.ajax({
        url: url,
        type: "post",
        data: serializedData,
        async: true
    }).done(function (response) {
        if (response.success) {
            $('#myProductTable').data('table').ajax.reload(null, false);
            form.removeClass("was-validated");
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        toggleLoading('#saveButtonRBO', '#loadingButtonRBO');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonRBO_04e4e017(event) {
    let form = $("#remove-backorder-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onAttributeChange_04e4e017() {
    $('#myProductTable').data('table').ajax.reload(null, false);
}

function onScannerToggle_04e4e017() {
    $('.scanner-toggle').toggleClass('btn-outline-primary btn-primary');
}

export function initMinimumStockPage() {
    require('../datatables');
    require('../barcode-scanner');

    let helper = $('#helper');
    let table = $('#myProductTable');
    let productCategory = $("#product_category");
    let backorder = $('#backorder');
    let partner = $('#product_partner');

    window.magnificPopup = magnificPopup;

    table.data('table', table.DataTable({
        "dom": '<"card-header border-bottom p-1"<"head-label"><"dt-action-buttons text-end"B>><"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "pageLength": 25,
        "ajax": {
            "url": helper.data('table'),
            "async": true,
            "data": function (d) {
                d.reset = false;
                d.partner = partner.val();
                d.backorder = backorder.val();
                d.product_category = productCategory.val();
            },
            "cache": false,
            "method": 'GET',
            "pages": 2
        },
        "order": [[5, 'asc'], [4, 'asc']],
        "language": {
            "url": helper.data('json')
        },
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "stateSave": true,
        "paging": true,
        "responsive": true,
        "buttons": [
            {
                text: '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAv0lEQVRIie3UsUoCUBTG8R9pgw6BoNATBA425ezkpoMgNPcMPUCrzxA4+ASBuDU3CSqCg5OLEoHQVkRLg0dwkkBEh/vB5XK43z3/wxk+kk6tTNwPKKGJNpZ4xn2cAq5xhwoe8YYO5ujiBj/x/wo1TLIBuMUlqiijj9bOIGvkUIy6gSfU0QtvHrPo8R19XBy+hP1KgARIgAQ4B8A2Tac2EZ3Du016vuz4RvH+EfUAX3jFZ3jHWGGIBX6PPHvSP/UHfIkfxOP+xdYAAAAASUVORK5CYII=" alt=""/>',
                className: 'btn btn-primary me-2 py-50 px-1 scanner-toggle',
                init: function (api, node) {
                    $(node).removeClass('btn-secondary');
                }
            },
            {
                extend: 'collection',
                className: 'btn btn-outline-secondary me-2',
                text: feather.icons.share.toSvg({class: 'font-small-4 me-50'}) + helper.data('export'),
                buttons: [
                    {
                        extend: 'print',
                        text: feather.icons.printer.toSvg({class: 'font-small-4 me-50'}) + helper.data('print'),
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        },
                        orientation: 'landscape',
                        customize: function (win) {
                            let css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    },
                    {
                        extend: 'csvHtml5',
                        text: feather.icons['file-text'].toSvg({class: 'font-small-4 me-50'}) + 'Csv',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'excelHtml5',
                        text: feather.icons.file.toSvg({class: 'font-small-4 me-50'}) + 'Excel',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'pdfHtml5',
                        text: feather.icons.clipboard.toSvg({class: 'font-small-4 me-50'}) + 'Pdf',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'copyHtml5',
                        text: feather.icons.copy.toSvg({class: 'font-small-4 me-50'}) + helper.data('copy'),
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [2, 3, 4, 5],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    }
                ],
                init: function (api, node) {
                    $(node).removeClass('btn-secondary');
                    $(node).parent().removeClass('btn-group');
                    setTimeout(function () {
                        $(node).closest('.dt-buttons').removeClass('btn-group').addClass('d-inline-flex');
                    }, 50);
                }
            },
            {
                text: helper.data('remove-backorder'),
                className: 'remove-backorder btn btn-outline-danger',
                attr: {
                    'data-bs-toggle': 'modal',
                    'data-bs-target': '#remove-backorder-modal'
                },
                init: function (api, node) {
                    $(node).removeClass('btn-secondary');
                }
            }
        ],
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                targets: 6,
                responsivePriority: 2,
            },
            {
                targets: 3,
                responsivePriority: 1
            },
            {
                className: 'control',
                orderable: false,
                targets: 0
            },
            {
                targets: 1,
                orderable: false,
                render: function (data) {
                    return (
                        '<div class="form-check"> <input class="form-check-input dt-checkboxes" name="selected-products[]" type="checkbox" value="' + data + '" id="checkbox-' +
                        data +
                        '" /><label class="form-check-label" for="checkbox-' +
                        data +
                        '"></label></div>'
                    );
                },
                checkboxes: {
                    selectAllRender:
                        '<div class="form-check"> <input class="form-check-input" type="checkbox" value="" id="checkboxSelectAll" /><label class="form-check-label" for="checkboxSelectAll"></label></div>'
                }
            },
        ],
    }).on('draw.dt', function () {
        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
        $("td").each(function () {
            $(this).addClass("text-center");
        });
        $('.image-popup-vertical-fit').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {verticalFit: true}
        });
        feather.replace({
            width: 14,
            height: 14
        });
    }).on('responsive-display', function () {
        $('.image-popup-vertical-fit').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {verticalFit: true}
        });

        feather.replace({
            width: 14,
            height: 14
        });
    }).on('preInit', function () {
        $('.buttons-colvis').addClass('btn btn-outline-secondary me-1');
    }).on('buttons-action', function () {
        $('.buttons-columnVisibility').addClass('dropdown-item');
    }));

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    $(document).codeScanner({
        onScan: function ($element, code) {
            if ($('.scanner-toggle').hasClass('btn-primary')) {
                $('.dataTables_filter label input').val(code);
                table.DataTable().search(code).draw();
            }
        }
    });

    partner.on('change', onAttributeChange_04e4e017);
    backorder.on('change', onAttributeChange_04e4e017);
    productCategory.on('change', onAttributeChange_04e4e017);
    $(document).on('click', '.scanner-toggle', onScannerToggle_04e4e017);
    $('#remove-backorder-form').on('submit', onRemoveBackorderForm_04e4e017);
    $('#saveButtonRBO').on('click', onSaveButtonRBO_04e4e017);
}

export function unloadMinimumStockPage() {
    delete window.magnificPopup;

    $('#myProductTable').DataTable().destroy();

    $("#backorder").off('change', onAttributeChange_04e4e017);
    $("#product_category").off('change', onAttributeChange_04e4e017);
    $('#product_partner').off('change', onAttributeChange_04e4e017);
    $(document).off('click', '.scanner-toggle', onScannerToggle_04e4e017);
    $('#remove-backorder-form').off('submit', onRemoveBackorderForm_04e4e017);
    $('#saveButtonRBO').off('click', onSaveButtonRBO_04e4e017);
}
